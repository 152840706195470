<template>
  <div
    class="bg-static-default-min group relative hover:bg-static-default-min-hover"
  >
    <div class="flex w-full items-center justify-between gap-16 p-12 text-left">
      <RevLink class="w-[calc(100%-56px)] no-underline" :to="toUrl">
        <!-- eslint-disable vue/no-v-html -->
        <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
        <span
          v-if="suggestion.highlightedTitle"
          class="text-action-default-low body-1 block flex-1 truncate"
          v-html="suggestion.highlightedTitle"
        />
        <span v-else class="block flex-1 truncate">
          {{ suggestion.title }}
        </span>
      </RevLink>
      <RevButtonIcon
        class="group-hover:visible lg:invisible"
        :class="!props.isPopularSearches ? 'md:hidden' : ''"
        :icon="IconArrowUpLeft"
        variant="secondary"
        @click="emit('select')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevLink } from '@ds/components/Link'
import { IconArrowUpLeft } from '@ds/icons/IconArrowUpLeft'

import type { Link } from '../algolia/algoliaFunctions'

const props = defineProps<{
  suggestion: Link
  isPopularSearches?: boolean
}>()

const toUrl = computed(() => {
  if (props.suggestion.link.type === 'internal') {
    const { params } = props.suggestion.link

    return `/${params.locale}/l/${params.slugV2}/${params.uuid}`
  }

  return props.suggestion.link.href
})

const emit = defineEmits(['select'])
</script>
