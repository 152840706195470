export default {
  newKeyword: {
    id: 'card_new_keyword',
    defaultMessage: 'new',
  },
  startingAt: {
    id: 'price_guide_highlight_cards_starting_at_price',
    defaultMessage: 'Starting at',
  },
  from: {
    id: 'buyback_my_sales_address_from',
    defaultMessage: 'From',
  },
}
