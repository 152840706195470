<template>
  <div
    class="text-action-default-hi flex items-center"
    :class="
      !hasTradeInExperiment && !hasImproveSearch
        ? 'rounded-sm relative pl-16 pr-4'
        : 'bg-static-default-mid rounded-full flex-row-reverse w-full gap-4 pl-8 pr-8 transition-colors duration-200 hover:bg-static-default-mid-hover'
    "
  >
    <button
      v-show="value && hasImproveSearch"
      :aria-label="i18n(translations.resetSearchButtonAriaLabel)"
      class="text-action-default-hi focus-visible-outline-inset-hi rounded-sm hidden h-32 w-32 cursor-pointer items-center justify-center transition-colors duration-100 hover:text-action-default-hi-hover md:block"
      type="reset"
      @click="resetHandler"
    >
      ✕
    </button>
    <input
      :id="inputId"
      ref="input"
      v-focus="isFocus"
      aria-describedby="resultsListBoxAlert"
      :aria-expanded="isListboxOpen"
      autocomplete="off"
      autocorrect="off"
      class="text-action-default-hi peer body-1 appearance-none bg-transparent outline-none placeholder:text-action-default-low"
      :class="
        !hasTradeInExperiment && !hasImproveSearch
          ? 'h-[38px] flex-1'
          : 'h-[40px] w-full text-ellipsis'
      "
      data-qa="search-bar-input"
      :name="inputName"
      :placeholder="i18n(translations.textboxPlaceholder)"
      role="searchbox"
      type="text"
      :value
      @focus="handleFocus"
      @input="changeHandler"
      @keydown.down.prevent="specialKeyHandler"
      @keydown.enter.prevent="submitHandler"
      @keydown.esc.prevent="resetHandler"
      @keydown.up.prevent="specialKeyHandler"
    />
    <template v-if="!hasTradeInExperiment && !hasImproveSearch">
      <div
        class="rounded-sm absolute -bottom-1 -left-1 -right-1 -top-1 -z-10 peer-focus-visible:outline-default-low"
      ></div>
      <button
        v-show="value"
        :aria-label="i18n(translations.resetSearchButtonAriaLabel)"
        class="text-action-default-hi focus-visible-outline-inset-hi rounded-sm flex h-32 w-32 cursor-pointer items-center justify-center transition-colors duration-100 hover:text-action-default-hi-hover"
        type="reset"
        @click="resetHandler"
      >
        ✕
      </button>
    </template>

    <button
      :aria-label="i18n(translations.searchButtonAriaLabel)"
      class="text-action-default-hi focus-visible-outline-inset-hi rounded-sm flex h-32 w-32 cursor-pointer items-center justify-center transition-colors duration-100 hover:text-action-default-hi-hover"
      type="submit"
      @click="submitHandler"
    >
      <IconSearch size="medium" />
    </button>
    <div id="resultsListBoxAlert" class="sr-only">
      <p role="alert">{{ listboxResultsMessage }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { vFocus } from '@ds/directives/focus'
import { IconSearch } from '@ds/icons/IconSearch'

import translations from './Textbox.translations'

const i18n = useI18n()

const emit = defineEmits(['change', 'submit', 'keydown', 'focus'])

const props = withDefaults(
  defineProps<{
    isFocus?: boolean
    value: string | null
    inputName?: string
    inputId: string
    isListboxOpen: boolean
    listboxResultsMessage: string
    hasTradeInExperiment?: boolean
    hasImproveSearch?: boolean
  }>(),
  {
    isFocus: false,
    inputName: 'q',
    hasTradeInExperiment: false,
    hasImproveSearch: false,
  },
)

const input = ref<HTMLInputElement | null>(null)

function submitHandler() {
  emit('submit', props.value)
}

function changeHandler(e: Event) {
  const { target } = e
  const { value } = target as HTMLInputElement
  emit('change', value)
}

function specialKeyHandler({ code }: { code: string }) {
  if (code === 'ArrowUp') {
    emit('keydown', -1)
  } else if (code === 'ArrowDown') {
    emit('keydown', 1)
  }
}

function handleFocus() {
  input.value?.focus()
  emit('focus')
}

function resetHandler() {
  emit('change', '')
  handleFocus()
}

function hydrated() {
  if (input.value === document.activeElement) {
    handleFocus()
  }
}

defineExpose({
  handleFocus,
})

onMounted(() => {
  hydrated()
})
</script>
