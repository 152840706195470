<template>
  <RevLink
    class="flex cursor-pointer justify-between px-12 no-underline shadow-none md:block md:px-0"
    :to="productPageLink"
  >
    <div class="pt-0 lg:p-16">
      <div
        class="flex grow content-start items-center justify-center gap-8 md:block lg:block"
        data-spec="image+main-information"
      >
        <div
          class="hidden flex-col items-center justify-center gap-2 md:flex lg:flex"
          data-spec="image+colors"
        >
          <RevIllustration
            :alt="title"
            :height="128"
            sizes="128px"
            :src="image"
            :width="128"
          />
          <ColorSwatches
            v-if="colorSwatches.length > 0"
            :colors="colorSwatches"
          />
        </div>
        <div class="md:hidden">
          <RevIllustration
            :alt="title"
            :height="24"
            sizes="24px"
            :src="image"
            :width="24"
          />
        </div>
        <div
          class="flex grow basis-[159px] flex-col items-start md:gap-6 lg:gap-6"
          data-spec="product-info"
        >
          <div class="flex flex-col gap-2" data-spec="titles">
            <h2>
              <span class="body-1-bold line-clamp-2">
                {{ title }}
              </span>
              <span
                v-if="description"
                class="text-static-default-low body-2 line-clamp-2"
              >
                {{ description }}
              </span>
            </h2>
          </div>
          <div class="hidden md:block lg:block">
            <ProductCardRatings
              v-if="reviewRating?.count && reviewRating?.average"
              :count="reviewRating.count"
              data-spec="rating"
              :score="reviewRating.average"
            />
          </div>
          <div v-if="price" data-spec="price-information">
            <ProductPrice
              :discounted-price="discountedPrice && i18n.price(discountedPrice)"
              :price="i18n.price(price)"
              :price-new="priceNew && i18n.price(priceNew)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center md:hidden lg:hidden">
      <RevButtonBase>
        <IconChevronRight />
      </RevButtonBase>
    </div>
  </RevLink>
</template>

<script setup lang="ts">
import type { Price } from '@backmarket/http-api'
import ColorSwatches from '@backmarket/nuxt-layer-recommendation/ColorSwatches.vue'
import ProductCardRatings from '@backmarket/nuxt-layer-recommendation/ProductCardRatings.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import ProductPrice from './ProductPrice.vue'

defineProps<{
  title: string
  image: string
  reviewRating?: {
    count: number
    average: number
  }
  description?: string
  price: Price
  priceNew?: Price
  discountedPrice?: Price
  colorSwatches: {
    name: string
    value?: string
  }[]
  productPageLink: string
}>()

const i18n = useI18n()
</script>
