<template>
  <div class="flex gap-4 md:flex-col lg:flex-col">
    <div class="text-static-default-hi caption md:hidden lg:hidden">
      {{ i18n(translations.from) }}
    </div>
    <div class="text-static-default-hi body-2 hidden md:block lg:block">
      {{ i18n(translations.startingAt) }}
    </div>
    <div
      class="text-static-default-hi caption md:body-2-bold lg:body-2"
      data-qa="productCardPrice"
    >
      {{ price }}
    </div>

    <div
      v-if="priceNew"
      class="text-static-default-low caption line-through md:body-2 lg:body-2"
    >
      {{ priceNew }}
      <span>
        {{ i18n(translations.newKeyword) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './ProductPrice.translations'

defineProps<{
  price: string
  priceNew?: string
}>()

const i18n = useI18n()
</script>
