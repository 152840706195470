import { useRuntimeConfig } from '#imports'
import { ref, watch } from 'vue'

import { createInMemoryCache } from '@algolia/cache-in-memory'
import type {
  AlgoliaLink,
  AlgoliaProduct,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchAlgolia'
import {
  type SearchBarConfiguration,
  getSearchBarConfiguration,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchConfiguration'
import {
  type SearchApiKey,
  getApiKey,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchKey'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useDebounceFn } from '@vueuse/core'
import algoliasearch, { type SearchIndex } from 'algoliasearch'

import {
  type Link,
  algoliaHitToLink,
  algoliaHitToProduct,
} from '../algolia/algoliaFunctions'

export function useAlgoliaSearchBar(
  options = { debounce: false, debounceTime: 500 },
) {
  const query = ref<string>('')
  const isLoading = ref(false)
  const algoliaIndexes = ref<string[]>()
  const productsHits = ref<Product[]>([])
  const suggestionsHits = ref<Link[]>([])
  const configuration = ref<SearchBarConfiguration | null>(null)
  const error = ref<Error | null>(null)
  const apiKey = ref<SearchApiKey | null>(null)
  const algoliaIndexProducts = ref<SearchIndex | null>(null)
  const algoliaIndexSuggestions = ref<SearchIndex | null>(null)
  const { ALGOLIA_ID } = useRuntimeConfig().public

  const fetchSearchApiKey = async () => {
    if (!configuration.value) {
      error.value = new Error('Missing search configuration')

      return
    }
    const { data, error: err } = await useHttpFetch(getApiKey, {
      pathParams: { index: configuration.value.backboxWinners.indexType },
    })
    apiKey.value = data.value
    error.value = err.value
  }

  const fetchSearchBarConfiguration = async () => {
    const { data, error: err } = await useHttpFetch(getSearchBarConfiguration)
    configuration.value = data.value
    error.value = err.value
  }

  const initAlgoliaIndexProducts = () => {
    if (configuration.value && apiKey.value) {
      algoliaIndexProducts.value = algoliasearch(
        ALGOLIA_ID,
        apiKey.value.apiKey,
        {
          requestsCache: createInMemoryCache({ serializable: false }),
          responsesCache: createInMemoryCache(),
        },
      ).initIndex(configuration.value.backboxWinners.indexes.active.name)
    }
  }

  const initAlgoliaIndexSuggestions = () => {
    if (configuration.value && apiKey.value) {
      algoliaIndexSuggestions.value = algoliasearch(
        ALGOLIA_ID,
        apiKey.value.apiKey,
        {
          requestsCache: createInMemoryCache({ serializable: false }),
          responsesCache: createInMemoryCache(),
        },
      ).initIndex(configuration.value.landingPages.indexes.active.name)
    }
  }

  const searchProducts = async () => {
    if (!algoliaIndexProducts.value || !query.value) return
    try {
      const resultProducts =
        await algoliaIndexProducts.value.search<AlgoliaProduct>(query.value, {
          filters: configuration.value?.backboxWinners.complexFilter,
        })
      productsHits.value = resultProducts.hits.map((hit) => {
        return algoliaHitToProduct(hit)
      })
    } catch (algoliaError) {
      error.value = new Error('Error while fetching Algolia', {
        cause: algoliaError,
      })
    }
  }

  const searchSuggestions = async () => {
    if (!algoliaIndexSuggestions.value || !query.value) return
    try {
      const resultSuggestions =
        await algoliaIndexSuggestions.value.search<AlgoliaLink>(query.value, {
          highlightPreTag: '<span class="body-1-bold text-static-default-hi">',
          highlightPostTag: '</span>',
          clickAnalytics: true,
          filters: configuration.value?.landingPages.complexFilter,
        })
      suggestionsHits.value = resultSuggestions.hits.map((hit) => {
        return algoliaHitToLink(hit)
      })
    } catch (algoliaError) {
      error.value = new Error('Error while fetching Algolia', {
        cause: algoliaError,
      })
    }
  }

  const search = async () => {
    if (
      !algoliaIndexProducts.value ||
      !algoliaIndexSuggestions.value ||
      !query.value
    )
      return

    isLoading.value = true
    try {
      await searchProducts()
      await searchSuggestions()
    } catch (algoliaError) {
      error.value = new Error('Error while fetching Algolia', {
        cause: algoliaError,
      })
    } finally {
      isLoading.value = false
    }
  }
  const performSearch = options.debounce
    ? useDebounceFn(search, options.debounceTime)
    : search

  watch(query, async () => {
    if (!algoliaIndexProducts.value || !algoliaIndexSuggestions.value) {
      initAlgoliaIndexProducts()
      initAlgoliaIndexSuggestions()
    }

    if (query.value === '') {
      productsHits.value = []
      suggestionsHits.value = []
    }
    if (query.value.length) {
      await performSearch()
    }
  })

  return {
    query,
    isLoading,
    algoliaIndexes,
    configuration,
    productsHits,
    suggestionsHits,
    error,
    apiKey,
    fetchSearchBarConfiguration,
    fetchSearchApiKey,
  }
}
