<template>
  <div>
    <div class="text-static-default-low body-2-bold grow">
      {{ title }}
    </div>
    <SuggestionItem
      v-for="suggestion in suggestions"
      :key="suggestion.id"
      :is-popular-searches="!inputValue"
      :suggestion
      @select="emit('select', suggestion.title)"
    />
    <div
      v-if="inputValue"
      class="bg-static-default-min hover:bg-static-default-min-hover"
      tabindex="-1"
    >
      <RevLink
        class="flex w-full px-8 py-12 text-left no-underline"
        :to="toUrlSearch"
      >
        <IconSearch size="medium" />
        <span class="body-1-bold flex-1 truncate pl-8">
          "{{ inputValue }}"
        </span>
      </RevLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevLink } from '@ds/components/Link'
import { IconSearch } from '@ds/icons/IconSearch'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import type { Link } from '../algolia/algoliaFunctions'

import SuggestionItem from './SuggestionItem.vue'

const props = defineProps<{
  inputValue?: string
  suggestions: Link[]
  title: string
}>()

const locale = useI18nLocale()
const emit = defineEmits(['select'])

const toUrlSearch = computed(() => {
  return `/${locale}/${PRODUCT_LIST_ROUTES.SEARCH_RESULTS}?q=${props.inputValue}`
})
</script>
