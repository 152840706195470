<template>
  <div>
    <p class="body-2-bold mb-16">{{ i18n(translations.tabProducts) }}</p>
    <div
      class="grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-16 lg:grid-cols-5"
    >
      <ProductCard
        v-for="product in products"
        :key="product.id"
        :color-swatches="product.specifications?.color ?? []"
        :description="product.description"
        :discounted-price="product.discountedPrice"
        :image="product.image"
        :price="product.price"
        :price-new="product.priceNew"
        :product-page-link="productPageLink(product)"
        :review-rating="product.reviewRating"
        :title="product.title"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import ProductCard from './ProductCard.vue'
import translations from './ProductSection.translations'

defineProps<{
  products: Product[]
}>()

const i18n = useI18n()

const productPageLink = (product: Product) => {
  const { params } = product.productPageLink

  return `/${params.locale}/p/${params.slug}/${params.uuid}`
}
</script>
