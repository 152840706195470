<template>
  <div ref="searchBarRef" v-click-outside="closeListbox" class="relative">
    <div class="flex gap-16">
      <Textbox
        :has-improve-search="true"
        :input-id="'search'"
        :input-name="SCHEMA_SEARCH_NAME"
        :is-listbox-open
        :listbox-results-message
        :value="searchInput"
        @change="changeHandler"
        @focus="openListbox"
        @reset="resetInput"
        @submit="goToSearch"
      />
      <button v-show="isListboxOpen" class="md:hidden" @click="resetInput">
        {{ i18n(translations.cancelLabel) }}
      </button>
    </div>
    <div
      v-if="isListboxOpen"
      id="listbox-content"
      ref="listboxContentRef"
      class="bg-surface-default-low border-static-default-low shadow-long fixed inset-0 z-[1] h-max w-full overflow-hidden border-t"
      :class="{ hidden: headerIsHidden }"
      :style="{ top: `${headerBarHeight}px` }"
    >
      <div
        v-if="searchInput"
        class="md:flex md:h-[60vh] md:flex-row-reverse lg:h-[390px]"
        :style="mobileHeightStyle"
      >
        <ProductSection
          class="h-[45%] flex-1 overflow-auto px-32 py-16 md:h-full md:pb-20 md:pt-32 lg:px-24"
          :products="productsHits"
        />
        <SuggestionsSection
          class="h-[55%] overflow-auto px-32 py-16 md:h-full md:w-[250px] md:py-32 md:pl-32 md:pr-0"
          :input-value="searchInput"
          :suggestions="suggestionsHits"
          :title="i18n(translations.suggestedSearches)"
          @click.stop
          @select="handleSelect"
        />
      </div>
      <div
        v-else-if="!searchInput && (popularSearches?.length ?? 0) > 0"
        class="px-16 py-32"
        :style="[mobileHeightStyle, desktopMaxWidthStyle]"
      >
        <SuggestionsSection
          :suggestions="popularSearches ?? []"
          :title="i18n(translations.popularSearches)"
          @click.stop
          @select="handleSelect"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { computed, ref, watch } from 'vue'

import { getPopular } from '@backmarket/http-api/src/api-specs-search-reco/search/searchPopular'
import { useHttpLazyFetch } from '@backmarket/nuxt-module-http/useHttpLazyFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { clickOutside as vClickOutside } from '@backmarket/utils/directives/ClickOutside'
import { useElementBounding } from '@vueuse/core'

import Textbox from '../SearchBar/components/Textbox.vue'
import type { Link } from '../algolia/algoliaFunctions'
import { useAlgoliaSearchBar } from '../composables/useSearchBar'

import ProductSection from './ProductSection.vue'
import translations from './SearchBar.translations'
import SuggestionsSection from './SuggestionsSection.vue'

const i18n = useI18n()
const locale = useI18nLocale()
const router = useRouter()
const {
  productsHits,
  suggestionsHits,
  query: searchInput,
  fetchSearchBarConfiguration,
  fetchSearchApiKey,
} = useAlgoliaSearchBar()
await fetchSearchBarConfiguration()
await fetchSearchApiKey()

const emit = defineEmits(['focus', 'blur'])

const props = defineProps<{
  headerIsHidden: boolean
  headerBarHeight: number
}>()

const isListboxOpen = ref(false)
const searchBarRef = ref<HTMLElement | null>(null)
const listboxContentRef = ref<HTMLElement | null>(null)
const { width: searchBarWidth } = useElementBounding(searchBarRef)

const { data: popularSearchesPayload } = useHttpLazyFetch(getPopular, {
  default: () => [],
})

const SCHEMA_SEARCH_NAME = 'q'

const popularSearches = computed(() => {
  return popularSearchesPayload.value
    ?.map((item) => {
      if (item.link.href)
        return item.highlightedTitle
          ? {
              title: item.title,
              highlightedTitle: item.highlightedTitle,
              id: item.landingId,
              link: item.link,
            }
          : {
              title: item.title,
              id: item.landingId,
              link: item.link,
            }

      return undefined
    })
    .filter((hit): hit is Link => hit !== undefined)
})

const listboxResultsMessage = computed(() => {
  const resultsCount = productsHits.value.length
  const searchQuery = searchInput.value
  const messageWhenQuery = i18n(translations.resultsListing, {
    resultsCount,
    searchQuery,
  })

  return searchInput.value
    ? messageWhenQuery
    : i18n(translations.textboxPlaceholder)
})

const mobileHeightStyle = computed(() => {
  if (window?.matchMedia('(max-width: 767px)')?.matches) {
    return { height: `calc(100vh - ${props.headerBarHeight}px)` }
  }

  return {}
})

const desktopMaxWidthStyle = computed(() => {
  if (window?.matchMedia('(min-width: 768px)')?.matches) {
    return { maxWidth: `${searchBarWidth.value}px`, margin: '0 auto' }
  }

  return {}
})

function openListbox() {
  isListboxOpen.value = true
  emit('focus')
}

function closeListbox() {
  isListboxOpen.value = false
  emit('blur')
}

function resetInput() {
  searchInput.value = ''
  closeListbox()
}

function changeHandler(value: string) {
  searchInput.value = value
  searchInput.value = value
}

function goToSearch() {
  router.push(`/${locale}/search?q=${searchInput.value}`)
  resetInput()
}

watch(searchInput, (newValue) => {
  if (newValue) {
    openListbox()
  } else {
    closeListbox()
  }
})

function handleSelect(title: string) {
  changeHandler(title)
  openListbox()
}
</script>
